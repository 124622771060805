.gbTicker {
  padding: 8px 0;
  background-color: #783bff;
  color: #FFFFFF;
  font-size: 16px;
  line-height: 1.5;
  text-align: center;
  width: 100%;

  a {
    color: inherit;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
}
