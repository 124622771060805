.header-image {
  width: 100%;
  height: 800px!important;
  position: absolute;
  background-repeat: no-repeat;
  background-position-x: center;
  z-index: -99999;
  background-position-y: bottom;
  background-image: url('./assets/header.svg');
  background-size: cover;
}

.header {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
}

.small-container {
  display: flex;
  flex-direction: column;
  width: 927px;
  align-items: center;
}

.subtitle {
  color: var(--default-opaque-neutrals-white, #FFF);
  text-align: center;
  font-size: 24px;
  font-weight: 300;
  line-height: 130%;
  width: 918px;
  margin: 0 auto;
  margin-top: 8px;
}

.tabs-container {
  height: 48px;
  margin-top: 40px;
  width: 100%;
  display: flex;
  justify-content: center;
}

.tab {
  border: none;
  width: 145px;
  border-radius: 8px 0px 0px 8px;
  background: var(--opaque-neutrals-gray-5, #F2F2F2);
  color: var(--opaque-neutrals-gray-80, #4A4A4A);
  font-size: 16px;
  font-weight: 600;
  line-height: 150%;
  height: 48px;
  cursor: pointer;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
}

.tab:not(.active):hover {
  background-color: #d6d6d6;
}

.second-tab {
  width: 148px;
  border-radius: 0px 8px 8px 0px;
  border-left: 1px solid #FFF;
}

.active {
  background: var(--opaque-secondary-secondary-50, #478585);
  color: var(--opaque-neutrals-white, #FFF);
}

.logo {
  margin-top: 48px;
  margin-bottom: 32px;
}

@media screen and (max-width: 979px) {
  .small-container {
    width: 90%;
  }

  .subtitle {
    width: 80%;
  }
}

@media screen and (max-width: 611px) {
  .subtitle {
    display: none;
  }
}