.container {
  width: 1168px;
  padding: 64px 0;
}

.title {
  color: var(--default-opaque-neutrals-gray-80, #4A4A4A);
  font-size: 32px;
  font-weight: 300;
  line-height: 130%;
  margin: 0;
}

@media screen and (max-width: 1300px) {
  .container {
    width: 83%;
  }
}