.container {
  width: 1168px;
}

.row {
  display: flex;
  gap: 64px;
  align-items: center;

  img {
    width: 500px;
  }
}

.section {
   margin-top: 64px;
   display: flex;
   flex-direction: column;
   gap: 64px;
   align-items: center;
}

.section:first-child {
  margin-top: 128px;
}

.about-content {
  display: flex;
  flex-direction: column;
  gap: 96px;
}

.text {
  color: var(--color-text, #4A4A4A);
  font-size: 18px;
  font-weight: 300;
  line-height: 150%;
  margin: 0;
}

.subtitle {
  color: var(--hover-opaque-neutrals-gray-80, var(--colors-hover-opaque-neutrals-gray-80, #3D3D3D));
  font-size: 24px;
  font-weight: 300;
  line-height: 130%;
  margin: 0;
}

.text-column {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

@media screen and (max-width: 1185px) {
  .container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .section {
    width: 90%;
    gap: 0;
  }

  .row {
    gap: 0;
    img {
      width: 50%;
    }
  }

  .about-content {
    gap: 8px;
  }

  .section:last-child {
    margin-top: 32px;
  }
}

@media screen and (max-width: 795px) {
  .about-content {
    gap: 96px;
  }

  .row {
    gap: 16px;

    svg {
      width: 368px!important;
      height: auto;
    }
  }

  .about-content .row:first-child div:first-child {
    order: 2;
  }

  .about-content .row:nth-child(2) div:first-child {
    order: 1;
  }

  .about-content .row:nth-child(3) div:first-child {
    order: 2;
  }

  .section > h2 {
    font-size: 48px;
  }

  .section:first-child {
    margin-top: 64px;
  }

  .section:last-child {
    margin-top: 128px;
  }
}

@media screen and (max-width: 700px) {
  .row {
    gap: 32px;
    flex-direction: column;
  }

  .section > h2 {
    font-size: 32px;
  }

  .about-content .row .text-column {
    order: 2!important;
  }
}

@media screen and (max-width: 450px) {
  .row svg {
    width: 288px!important;
  }
}